import { useSelector } from 'react-redux';
import { ENVIRONMENT } from 'config';
import selectors from './selectors';

const getEventBody = (event) => {
  const selector = selectors[event];

  if (!selector) {
    if (ENVIRONMENT === 'development') {
      console.error('Event "%s" not found', event); // eslint-disable-line
    }
    return () => ({});
  }

  return selector;
};

const useEvents = new Proxy(useSelector, {
  apply: (target, thisArg, args) => {
    const selector = getEventBody.apply(null, args);
    return target(selector);
  },
});

export default useEvents;
