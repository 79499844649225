import { getLogger, level } from 'app/lib/logger';

const logger = getLogger('[Deprecated]', level.debug, console);

export function Deprecated(validator) {
  return (props, propName, componentName, ...rest) => {
    if (props[propName] != null) {
      logger.debug(
        `The '${propName}' prop  in the '${componentName}' component is deprecated`
      );
    }

    return validator(props, propName, componentName, ...rest);
  };
}
