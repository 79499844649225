import { ENTITIES } from 'app/lib/analytics/entityContexts';
import {
  getAnalyticsBundleSelectedProducts,
  getAnalyticsBundlePrices,
  getAnalyticsBundlesProductsQuantities,
} from 'redux/modules/Bundles/api';
import { ServiceSelectionApi } from 'redux/modules/ServiceSelection/api';

export default {
  [ENTITIES.CORE_SERVICES]: (state) => {
    // ServiceSelection should override bundles, if there are any selectedServices
    // getSelectedServicesAnalytics will return an empty object if selectedServices is
    // an empty array, making sure the bundles page works correctly still
    //
    // probably worth doing something different (better) later on to make sure this
    // works more reliably.
    //
    // UPDATE: this is now only responsible for the core service context around the bundles page
    // On the further steps the core service context is sourced from the bundles page
    return {
      ...getAnalyticsBundleSelectedProducts(state),
      ...ServiceSelectionApi.getSelectedServicesAnalytics(state),
      ...getAnalyticsBundlePrices(state),
      ...getAnalyticsBundlesProductsQuantities(state),
    };
  },
};
