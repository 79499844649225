import { EVENTS } from 'app/lib/analytics/constants';
import { EnergyProductSelectionApi } from 'redux/modules/Energy/ProductSelectionForm/api';

/*
 *- name: fuel_choice
        description: "Choice of fuel types, e.g. gas/elec/both"
        type: STRING
        required: true
      - name: meter_type
        description: "Choice of meters"
        type: STRING
        required: true
      - name: payment_type
        description: "Choice of payment type, DD or prepay"
        type: STRING
        required: true
      - name: energy_usage
        description: "Choice of preset energy consumption estimates, or known usage option"
        type: STRING
        required: true
      - name: entered_known_usage
        description: "Whether the user entered a known usage value"
        type: STRING
        required: false
      - name: payment_option
        description: "Choice of payment option"
        type: STRING
        required: true
 */
export default {
  [EVENTS.ENERGY_QUOTE_REQUESTED]: (state) => {
    const fields = EnergyProductSelectionApi.getFormFields(state);
    const enteredKnownUsage =
      !!fields.gas ||
      !!fields.electricityConsumption ||
      !!fields.electricityDayConsumption ||
      !!fields.electricityNightConsumption;

    return {
      fuel_type: fields.energy,
      meter_type: fields.meter,
      payment_method: fields.paymentType,
      energy_usage: fields.energyUse,
      entered_known_usage: enteredKnownUsage,
      billing_plan: fields.paymentPlan,
    };
  },
};
