import { EVENTS } from 'app/lib/analytics/constants';
import { OrderSummaryApi } from 'app/redux/modules/OrderSummary/api';

/*
payment-requested:
  description: user presented with the payment form
  destinations: [mparticle->mixpanel, mparticle->bq]
  source: frontend
  fields:
    amount: {type: integer, description: one off depoist amount requested to pay}
payment-submitted:
  description: user submits their payment card details intiating payment
  destinations: [mparticle->mixpanel, mparticle->bq]
  source: frontend
payment-errored:
  description: payment failed due to technical issues
  destinations: [mparticle->mixpanel, mparticle->bq]
  source: frontend
  fields:
    reason: {type: string, description: highlevel error type}
payment-rejected:
  description: payment failed due to gateway rejection
  destinations: [mparticle->mixpanel, mparticle->bq]
  source: frontend
  fields:
    reason: {type: string, description: highlevel error type}
payment-completed:
  description: payment successfully finished
  destinations: [mparticle->mixpanel, mparticle->bq]
  source: frontend
*/

export default {
  [EVENTS.PAYMENT_REQUESTED]: (state) => {
    const deposits = OrderSummaryApi.getDeposits(state);
    const paymentAmount = OrderSummaryApi.getPaymentAmount(state);

    const amount = deposits?.total?.value ?? paymentAmount?.value;
    return {
      amount,
    };
  },
};
