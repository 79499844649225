import { getOptimizelyClient } from 'app/lib/analytics';

export default () => {
  const client = getOptimizelyClient();

  if (!client?.userContext?.userId) {
    return () => {};
  }

  return (event, eventTags = {}) => {
    if (!event) throw TypeError('no event declared');
    client.track(event, eventTags);
  };
};
