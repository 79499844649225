import { ENTITY_PROPERTIES, EVENT_ENTITY_MAP } from '../entityContexts';

export const getEntityContext = (name, version, domain) => {
  const contextDataPoints = ENTITY_PROPERTIES[name] || [];

  return {
    contextDataPoints,
    entityName: name,
    version,
    domain,
  };
};

export const getEventEntityContexts = (event) => {
  const entities = EVENT_ENTITY_MAP[event] ?? [];

  return entities.map((entity) => {
    const { entityName, version, domain } = entity;
    return getEntityContext(entityName, version, domain);
  });
};

export const addEntityInterceptor = (payload) => {
  const snowplowContext = payload?.options?.snowplow?.contexts ?? [];
  return {
    ...payload,
    options: {
      ...payload.options,
      snowplow: {
        ...payload.options.snowplow,
        contexts: [
          ...snowplowContext,
          ...getEventEntityContexts(payload.event),
        ],
      },
    },
  };
};
