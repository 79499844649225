import {
  ADD_ON_BILL_PROTECTOR,
  ADD_ON_CASHBACK_CARD,
} from 'app/redux/modules/OrderSummary/constants';

export const formatProductName = (name) => {
  if (name === ADD_ON_BILL_PROTECTOR) return 'bill_protector';
  if (name === ADD_ON_CASHBACK_CARD) return 'cashback_card';
  return name.toLowerCase();
};
