// local logger
export const level = {
  debug: 'debug',
  info: 'info',
  error: 'error',
};

export const getLogger = (prefix, lvl, base) => {
  let debug = () => {};
  let info = () => {};
  let error = (...args) => base.error(prefix, ...args);

  if (lvl === level.debug) {
    debug = (...args) => base.debug(prefix, ...args);
  }

  if (lvl === level.debug || lvl === level.info) {
    info = (...args) => base.info(prefix, ...args);
  }

  const logger = {
    debug,
    info,
    error,
    prefix,
  };

  const child = (childPrefix, childLvl) => {
    return getLogger(`${logger.prefix}${childPrefix}`, childLvl, base);
  };

  logger.child = child;

  return logger;
};
