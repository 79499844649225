export const ConsentScope = {
  STRICTLY_NECESSARY: 'Strictly necessary cookies',
  FUNCTIONAL: 'Functional cookies',
  ADVERTISING: 'Advertising cookies',
  PERFORMANCE: 'Performance cookies',
  SOCIAL_MEDIA: 'Social Media Cookies',
};

export const getCookieGroups = () => {
  if (window?.OneTrust) {
    return window?.OneTrust.GetDomainData().Groups.filter(
      (group) => group.Cookies.length
    );
  }

  return [];
};

export const getConsentScopes = () => {
  if (!window?.OneTrust) return [];

  const { OptanonActiveGroups } = window;
  const cookieGroups = getCookieGroups();
  // get currently active consent groups as
  // specified by OneTrust consent
  const activeCookieGroupIds = OptanonActiveGroups.split(',').filter(Boolean);

  const consentScopes = activeCookieGroupIds.map(
    (groupId) =>
      cookieGroups.find((group) => groupId === group.CustomGroupId)?.GroupName
  );

  return consentScopes;
};
