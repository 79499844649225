import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { OptimizelyProvider as Provider } from '@optimizely/react-sdk';
import { client as snowplowClient } from '@utilitywarehouse/partner-tracking-adapter-snowplow-web';
import { getOptimizelyClient } from 'app/lib/analytics';
import { EntryFormApi } from 'redux/modules/EntryForm/api';
import { PartnerLoginApi } from 'redux/modules/PartnerLogin/api';
import { getSalesMethod, getOrigin } from 'redux/modules/Analytics/api';
import notificationListeners from '../notificationListeners';
import { ApplicationApi } from 'redux/modules/App/api';

function isLocalEnv() {
  return process.env.NODE_ENV === 'development';
}

function getLocalUser() {
  let optlyUserId = window.localStorage.getItem('optlyUserId');
  if (!optlyUserId) {
    optlyUserId = 'localUser';
    window.localStorage.setItem('optlyUserId', optlyUserId);
  }

  return optlyUserId;
}

function getUserId() {
  const domainUser = snowplowClient.getDomainUserId();

  if (isLocalEnv()) {
    window.optlyUserId = getLocalUser();
  }

  return isLocalEnv() ? window.optlyUserId : domainUser;
}

const OptmizelyProvider = ({ children }) => {
  const optimizely = getOptimizelyClient();
  const partnerId = useSelector(PartnerLoginApi.getPartnerId);
  const isHomeOwner = useSelector(EntryFormApi.isHomeOwner);
  const origin = useSelector(getOrigin) ?? 'Unknown';
  const salesMethod = useSelector(getSalesMethod);
  const isRemoteSession = useSelector(ApplicationApi.isRemoteSession);
  const appId = useSelector(ApplicationApi.getCurrentApplication);

  const userId = isRemoteSession && !isLocalEnv() ? appId : getUserId();

  React.useEffect(() => {
    if (optimizely) {
      notificationListeners(optimizely);
    }
  }, [optimizely]);

  if (!optimizely) return children;

  return (
    <Provider
      optimizely={optimizely}
      user={{
        id: userId,
        attributes: {
          position_id: partnerId,
          homeowner_status: isHomeOwner ? 'homeowner' : 'tenant',
          origin,
          sales_method: salesMethod,
        },
      }}
    >
      {children}
    </Provider>
  );
};

OptmizelyProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OptmizelyProvider;
