import { EVENTS } from 'app/lib/analytics/constants';
import { RemoteSessionApi } from 'redux/modules/RemoteSession/v2/api';

export default {
  [EVENTS.REMOTE_CONTROL_CHANGE]: (state) => {
    return {
      from: RemoteSessionApi.getPreviousControl(state),
      to: RemoteSessionApi.getControlActor(state),
    };
  },
};
