import * as Routes from 'redux/modules/Routes/constants';
export const routesTrackingMap = {
  [Routes.LandingPage]: 'landing_page',
  [Routes.RemoteJoinPage]: 'remote_appointment_join_page',
  [Routes.RemotePage]: 'remote_appointment_start_page',
  [Routes.RemoteWithCustomerPage]: 'remote_appointment_half_remote_start_page',
  [Routes.RemoteSupportPage]: 'remote_appointment_supporter_join_page',
  [Routes.Restore]: 'application_restore_page',
  [Routes.ApplicationPresentationPage]: 'remote_presentation_page',
  [Routes.EntryOrganic]: 'landing_organic',
  [Routes.BundlesPage]: 'bundle_select_page',
  [Routes.EnergySelection]: 'energy_selection_page',
  [Routes.EnergyQuote]: 'energy_quote_page',
  [Routes.EnergyDetails]: 'energy_summary_page',
  [Routes.Broadband]: 'broadband_page',
  [Routes.MobileTariffSelect]: 'mobile_tariff_selection_page',
  [Routes.BoilerCover]: 'boilercover_page',
  [Routes.InsuranceBoiler]: 'boilercover_page',
  [Routes.InsuranceBIP]: 'bill_income_page',
  [Routes.CashbackCardUpsell]: 'cashbackcard_page',
  [Routes.AddOnsCashback]: 'cashbackcard_page',
  [Routes.BillProtector]: 'billprotector_page',
  [Routes.OrderSoFar]: 'order_summary_page',
  [Routes.YourDetails]: 'your_details_page',
  [Routes.OrderConfirmation]: 'order_confirmation_page',
  [Routes.Payment]: 'payment_page',
  [Routes.ThankYou]: 'thankyou_page',
  [Routes.FingerprintsWithChallenge]: 'payment_verification_page',
  [Routes.VerificationEnd]: 'payment_verification_complete_page',
  [Routes.PartnerSSO]: 'partner_sso_page',
  [Routes.PartnerProxyLogin]: 'partner_proxy_sign_in_page',
  [Routes.SalesLogin]: 'sales_sign_in_page',
  [Routes.SalesLoginIAM]: 'sales_iam_sign_in_page',

  [Routes.EntryFormIntro]: 'entry_form_start_page',
  [Routes.EntryFormAddress]: 'entry_form_address_confirm_page',
  [Routes.EntryFormLandline]: 'entry_form_landline_page',
  [Routes.EntryFormProperty]: 'entry_form_property_page',
  [Routes.EntryFormMeters]: 'entry_form_meters_page',
};
