import { EVENTS } from 'app/lib/analytics/constants';
import { OrderSummaryApi } from 'app/redux/modules/OrderSummary/api';

export default {
  [EVENTS.PROSPECT_CREDIT_CHECK_COMPLETED]: (state) => {
    const { deposit } = OrderSummaryApi.getTotals(state);
    return {
      updated_deposit: deposit,
    };
  },
};
