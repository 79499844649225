import { reduxStore } from 'app';

import { EntryFormApi } from 'redux/modules/EntryForm/api';
import { RemoteSessionApi } from 'redux/modules/RemoteSession/v2/api';
import { ApplicationApi } from 'app/redux/modules/App/api';
import { PartnerLoginApi } from 'app/redux/modules/PartnerLogin/api';
import { getOrigin, getSalesMethod } from 'app/redux/modules/Analytics/api';
import { SUPPORT_REQUIRED_MANDATORY } from 'redux/modules/PartnerLogin/constants';
import {
  EVENT_TYPE,
  META_CONTEXT_VERSION,
  META_CONTEXT_ENTITY,
} from '../constants';

// TODO deprecated
const getSessionType = (state) => {
  const sessionTypes = {
    ORGANIC: 'organic',
    SALES: 'sales',
    REMOTE: 'remote',
    HALF_REMOTE: 'half_remote',
    FACE_TO_FACE: 'face_to_face',
  };
  const isRemote = ApplicationApi.isRemoteSession(state);
  const isSales = ApplicationApi.isSalesApplication(state);
  const isOrganic = ApplicationApi.isOrganicApplication(state);
  const isHalfRemote = RemoteSessionApi.isHalfRemote(state);

  if (isOrganic) {
    return sessionTypes.ORGANIC;
  }

  if (isSales) {
    return sessionTypes.SALES;
  }

  if (isHalfRemote) {
    return sessionTypes.HALF_REMOTE;
  }

  if (isRemote) {
    return sessionTypes.REMOTE;
  }

  return sessionTypes.FACE_TO_FACE;
};

function getIsAdTraffic(state) {
  const params = ApplicationApi.getPageParams(state);
  return params?.utm_medium === 'ppc';
}

function getAdCampaign(state) {
  const params = ApplicationApi.getPageParams(state);
  return params?.utm_campaign;
}

export const contextInterceptor = (payload) => {
  const state = reduxStore.getState();

  const contextDataPoints = [
    'created_at',
    'application_id',
    'is_home_owner',
    'is_supported',
    'is_partner_referred',
    'is_support_required',
    'session_type',
    'control',
    'remote_actor',
    'promotion_is_ad',
    'promotion_campaign',
    'event_type',
    'sales_method',
    'origin',
  ];

  const contexts = payload?.options?.snowplow?.contexts ?? [];
  return {
    ...payload,
    options: {
      ...payload.options,
      snowplow: {
        ...payload.options.snowplow,
        contexts: [
          {
            contextDataPoints,
            entityName: META_CONTEXT_ENTITY,
            version: META_CONTEXT_VERSION,
          },
          ...contexts,
        ],
      },
    },
    context: {
      created_at: new Date().toISOString(),
      application_id: ApplicationApi.getCurrentApplication(state) || undefined,
      is_home_owner: EntryFormApi.isHomeOwner(state),
      is_supported:
        !!PartnerLoginApi.getSupportingId(state) ||
        !!ApplicationApi.getRemoteSupportingPartnerId(state),
      is_partner_referred: !!PartnerLoginApi.getHostedId(state),
      is_support_required:
        PartnerLoginApi.getSupportRequiredStatus(state) ===
        SUPPORT_REQUIRED_MANDATORY,
      session_type: getSessionType(state),
      control: RemoteSessionApi.getControlActor(state) || undefined,
      remote_actor: RemoteSessionApi.getActor(state)?.type || undefined,
      promotion_is_ad: getIsAdTraffic(state),
      promotion_campaign: getAdCampaign(state) || undefined,
      event_type: EVENT_TYPE.APPLICATION,
      sales_method: getSalesMethod(state),
      origin: getOrigin(state),
      ...payload.context,
    },
  };
};
