import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTracking } from '@utilitywarehouse/partner-tracking-react';
import { withRouter } from 'react-router-dom';

import { EVENT_TYPE } from 'app/lib/analytics/constants';
import { routesTrackingMap } from './trackingMap';

const TrackPages = ({ location }) => {
  const tracking = useTracking();
  const pathname = location?.pathname;

  useEffect(() => {
    if (pathname) {
      if (routesTrackingMap[pathname]) {
        tracking.page(routesTrackingMap[pathname], {
          pathname,
          event_type: EVENT_TYPE.PAGE_VIEW,
        });
      } else {
        tracking.page('unknown_route', {
          pathname,
          event_type: EVENT_TYPE.PAGE_VIEW,
        });
      }
    }
  }, [pathname, tracking]);
  return null;
};

TrackPages.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(TrackPages);
