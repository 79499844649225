import applicationSelectors from './application';
import remoteSelectors from './remote';
import riskSelectors from './risk';
import paymentSelectors from './payments';
import entitySelectors from './entities';
import energySelectors from './energy';
import broadbandSelectors from './broadband';
import mobileSelectors from './mobile';

export default {
  ...applicationSelectors,
  ...remoteSelectors,
  ...riskSelectors,
  ...paymentSelectors,
  ...entitySelectors,
  ...energySelectors,
  ...broadbandSelectors,
  ...mobileSelectors,
};
