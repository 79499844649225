/**
 * Create product object, used to send with eCommerce events
 *
 * @param price{Number} - price of product, decimalised
 * @param name{string} - name of product, as it will appear on analytics
 * @param key{string} - SKU/key of product, unique identifier
 * @param count{Number} - quantity of products, defaults to 1
 *
 * @return Object
 */
export function createProduct(price, name, key, count = 1) {
  if (isNaN(Number(price))) throw new TypeError('product price is required');
  if (!name) throw new TypeError('product name is required');
  if (!key) throw new TypeError('product key is required');
  if (isNaN(price)) throw new TypeError('price must be a valid number');

  return {
    Name: name,
    Price: price,
    Sku: key,
    Quantity: count,
  };
}
