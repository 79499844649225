import { useEffect } from 'react';

const defaultOptions = {
  threshold: 0.8,
};

const getEffect = (ref, callback, options = defaultOptions) => {
  return () => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;

      return callback({ visible: entry?.isIntersecting ?? false });
    }, options);

    if (ref.current) observer.observe(ref.current);

    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  };
};

/**
 * Proxy of useEffect placing an IntersectionObserver when a defined element
 * is present in the DOM, determined from the supplied ref.
 *
 * Usage:
 *
 * ```
 * const ref = React.useRef()
 *
 * useScrollIntoView(ref, ({ visible }) => {
 *   if (visible) doThing();
 * });
 *
 * // ...
 *
 * <div ref={ref} />
 * ```
 *
 * @param {Object} ref - target element ref
 * @param {Function} callback - callback function to run on each intersection event
 * @param {Object} options - IntersectionObserver options
 *
 */
const useScrollIntoView = new Proxy(useEffect, {
  apply: (target, thisArg, args) => {
    const [ref] = args;
    return target(getEffect(...args), [ref]);
  },
});

export default useScrollIntoView;
