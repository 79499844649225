import { enums } from '@optimizely/react-sdk';
import { getClient } from 'app/lib/analytics';
import { EVENTS, OPTLY_DOMAIN } from 'app/lib/analytics/constants';

const minDuration = 1000 * 60 * 5;

const onDecision = (() => {
  const timestamps = {};
  const genKey = (flagKey, variationKey) => `${flagKey}+${variationKey}`;

  return ({ type, userId, decisionInfo }) => {
    const analyticsClient = getClient();
    if (type === 'flag') {
      const { flagKey, enabled, variationKey } = decisionInfo;
      const decisionKey = genKey(flagKey, variationKey);

      const ts = Date.now();
      if (ts - timestamps[decisionKey] < minDuration) return;

      analyticsClient.track(
        EVENTS.OPTLY_DECIDE_TRACK,
        {
          experiment_key: flagKey,
          enabled,
          variation_key: variationKey,
          user_id: userId,
        },
        {
          snowplow: {
            domain: OPTLY_DOMAIN,
          },
        }
      );

      timestamps[decisionKey] = Date.now();
    }
  };
})();

const onTrack = (() => {
  const timestamps = {};

  return (event) => {
    const analyticsClient = getClient();
    const { eventKey, eventTags, userId } = event;
    const { revenue, value } = eventTags;

    const ts = Date.now();
    if (ts - timestamps[eventKey] < minDuration) return;

    analyticsClient.track(
      EVENTS.OPTLY_EVENT_TRACK,
      {
        event_key: eventKey,
        user_id: userId,
        value,
        revenue,
      },
      {
        snowplow: {
          domain: OPTLY_DOMAIN,
        },
      }
    );

    timestamps[eventKey] = Date.now();
  };
})();

export default (optimizelyClient) => {
  optimizelyClient.notificationCenter.addNotificationListener(
    enums.NOTIFICATION_TYPES.DECISION,
    onDecision
  );

  optimizelyClient.notificationCenter.addNotificationListener(
    enums.NOTIFICATION_TYPES.TRACK,
    onTrack
  );
};
