import { getOptimizelyClient } from 'app/lib/analytics';

export default function getDecision(feature) {
  const client = getOptimizelyClient();
  if (!client) {
    return {
      enabled: false,
      variant: null,
    };
  }
  const decision = client.decide(feature);

  if (!decision) {
    return {
      enabled: false,
      variant: null,
    };
  }

  return {
    enabled: decision.enabled,
    variant: decision.variationKey,
  };
}
