import { useDecision } from '@optimizely/react-sdk';

export default (feature) => {
  if (!feature) throw TypeError('no feature declared');
  const [decision] = useDecision(feature);

  return {
    enabled: decision.enabled,
    variant: decision.variationKey,
  };
};
