import { includes, pathOr } from 'lodash/fp';
import { createMuiTheme } from '@material-ui/core/styles';
import { createTheme as createPartnerUiTheme } from '@utilitywarehouse/partner-ui-mui-theme';

const allowedThemes = ['light', 'dark'];

export const createTheme = (variant = 'light') => {
  if (!includes(variant)(allowedThemes)) {
    throw new Error(`Invalid theme "${variant}"`);
  }

  return createMuiTheme(createPartnerUiTheme(variant));
};

export const getThemeProp = (prop, defaultValue = null) => (theme) =>
  pathOr(defaultValue, prop)(theme);

export const getBrandPaletteColor = (variant) => (theme) =>
  getThemeProp(`palette.brand.${variant}`, null)(theme);

export const getErrorPaletteColor = (variant) => (theme) =>
  getThemeProp(`palette.error.${variant}`, null)(theme);

export const getPaletteShadeGrey = (shade) => (theme) =>
  getThemeProp(`palette.grey.${shade}`, null)(theme);

export const getInfoPaletteColor = (variant = 'main') => (theme) =>
  getThemeProp(`palette.info.${variant}`, null)(theme);

export const getTextPaletteColor = (variant = 'primary') => (theme) =>
  getThemeProp(`palette.text.${variant}`, null)(theme);

export const getTypography = (variant) => (theme) =>
  getThemeProp(`typography.${variant}`, null)(theme);

export const getBackgroundPaletteColor = (variant) => (theme) =>
  getThemeProp(`palette.background.${variant}`, null)(theme);

export const getCommonPaletteColor = (variant) => (theme) =>
  getThemeProp(`palette.common.${variant}`, null)(theme);

export const getLinePaletteColor = (variant) => (theme) =>
  getThemeProp(`palette.line.${variant}`, null)(theme);

export const getPrimaryPaletteColor = (variant) => (theme) =>
  getThemeProp(`palette.primary.${variant}`, null)(theme);

export const getSecondaryPaletteColor = (variant) => (theme) =>
  getThemeProp(`palette.secondary.${variant}`, null)(theme);

export const getActionPaletteColor = (variant) => (theme) =>
  getThemeProp(`palette.action.${variant}`, null)(theme);

export const getSuccessPaletteColor = (variant) => (theme) =>
  getThemeProp(`palette.success.${variant}`, null)(theme);

export const getWarningPaletteColor = (variant) => (theme) =>
  getThemeProp(`palette.warning.${variant}`, null)(theme);

export const getServicePaletteColor = (variant) => (theme) =>
  getThemeProp(`palette.services.${variant}`, null)(theme);

export const getSpacing = (theme) => getThemeProp('spacing', () => 0)(theme);

export const getBreakpoints = (theme) =>
  getThemeProp('breakpoints', {
    down: () => '@media (max-width 999999px)',
    up: () => '@media (min-width 0px)',
  })(theme);
